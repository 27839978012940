import React, { useState } from 'react';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Button, { buttonTypes } from '@nutkit/component-button';
import Input from '@nutkit/component-input';
import Panel from '@nutkit/component-panel';
import Select from '@nutkit/component-select';
import { Heading } from '@nutkit/component-text';

import { AUTH0_ORGANISATIONS } from '../../constants';
import Layout from '../Layout';

const ImpersonateStart = () => {
  const [organisation, setOrganisation] = useState('');
  const [customerUuid, setCustomerUuid] = useState('');
  const redirectRoute = `/impersonate?user_id=${customerUuid}&org_id=${organisation}`;
  const { t } = useTranslation();
  const TRANSLATION_NAMESPACE = 'auth.common.impersonateStart';

  const handleRedirect = e => {
    e.preventDefault();
    window.location.assign(redirectRoute);
  };

  return (
    <Layout>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
      <Panel>
        <form data-qa="impersonation-start-form" onSubmit={e => handleRedirect(e)}>
          <Select
            id="organisation"
            title={t(`${TRANSLATION_NAMESPACE}.organisation.title`)}
            label={t(`${TRANSLATION_NAMESPACE}.organisation.label`)}
            options={[
              { id: AUTH0_ORGANISATIONS.NUTMEG, title: t(`${TRANSLATION_NAMESPACE}.organisation.nutmeg`) },
              { id: AUTH0_ORGANISATIONS.JOHN_LEWIS_FINANCE, title: t(`${TRANSLATION_NAMESPACE}.organisation.jlp`) },
              { id: AUTH0_ORGANISATIONS.CHASE, title: t(`${TRANSLATION_NAMESPACE}.organisation.chase`) }
            ]}
            onSelect={id => setOrganisation(id)}
            value={organisation}
            data-qa="impersonation-start-select-organisation"
          />
          <Input
            id="uuid"
            type="text"
            label={t(`${TRANSLATION_NAMESPACE}.customerUuid.label`)}
            onChange={event => {
              setCustomerUuid(event.target.value);
            }}
            value={customerUuid}
            data-qa="impersonation-start-input-uuid"
          />
          <Button
            data-qa="impersonation-start-submit"
            type={buttonTypes.SUBMIT}
            disabled={!(organisation && customerUuid)}
          >
            {t(`${TRANSLATION_NAMESPACE}.submit`)}
          </Button>
        </form>
      </Panel>
    </Layout>
  );
};

export default ImpersonateStart;
