import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@nm-utils-lib-web/translations';
import queryString from 'query-string';
import { useAuth } from '@nm-utils-lib-web/authentication';
import Loading from '@nutkit/component-loading';
import { VALID_UUID_REGEX } from '@nm-utils-lib-web/routes/redirects';
import cookies from 'browser-cookies';
// eslint-disable-next-line
import { generateIsAuthenticatedCookieName } from '@nm-utils-lib-web/authentication/src/utils/isAuthenticatedCookie/generateIsAuthenticatedCookieName';

import { setAuthCookies } from '../../helpers';
import { AUTH0_ORGANISATIONS, CONNECTIONS, ROUTES } from '../../constants';
import { GenericError } from '../GenericError';

// Convert Auth0 organisation (org_id) to k8s config cased equivalent
const getAuthHost = organisation => window.NutmegConfig[`AUTH_HOST_${organisation.toUpperCase().replace('-', '_')}`];
const validateUserId = user_id => user_id && VALID_UUID_REGEX.test(user_id);
const isValidAuth0Organisation = organisation =>
  [AUTH0_ORGANISATIONS.NUTMEG, AUTH0_ORGANISATIONS.JOHN_LEWIS_FINANCE, AUTH0_ORGANISATIONS.CHASE].includes(
    organisation
  );
const TRANSLATION_NAMESPACE = 'auth.common.error';

const ImpersonateLogin = () => {
  const { loginWithRedirect } = useAuth();
  const { search } = useLocation();
  const { user_id, org_id } = queryString.parse(search);
  const isValidUserId = validateUserId(user_id);
  const isValidOrgId = isValidAuth0Organisation(org_id);
  const { t } = useTranslation();
  const authHost = getAuthHost(org_id);
  const isOnCorrectHost = authHost === window.location.origin;
  const isAuthenticatedCookieName = generateIsAuthenticatedCookieName();
  const hasExistingSession = cookies.get(isAuthenticatedCookieName);

  if (isOnCorrectHost) {
    setAuthCookies(true, CONNECTIONS.CHASE_SSO);
  }

  React.useEffect(() => {
    if (isValidUserId && isValidOrgId && !hasExistingSession) {
      if (isOnCorrectHost) {
        loginWithRedirect({
          authorizationParams: {
            connection: CONNECTIONS.CHASE_SSO,
            org_id,
            redirect_uri: `${authHost}${ROUTES.IMPERSONATE_CALLBACK}`,
            user_id
          }
        });
      } else {
        const currentPath = window.location.pathname + window.location.search;

        window.location.assign(authHost + currentPath);
      }
    }
  });

  if (!isValidUserId || !isValidOrgId) {
    return (
      <GenericError
        enableMonitoring={false}
        ctaText={t(`${TRANSLATION_NAMESPACE}.cta`)}
        ctaPath="/logout"
        data-qa="error-invalid-params"
      />
    );
  }

  if (hasExistingSession) {
    return (
      <GenericError
        enableMonitoring={false}
        ctaText={t(`${TRANSLATION_NAMESPACE}.logoutBtn`)}
        ctaPath="/logout"
        data-qa="error-existing-session"
      >
        {t(`${TRANSLATION_NAMESPACE}.existingSession`)}
      </GenericError>
    );
  }

  return <Loading data-qa="impersonate-login-loader" />;
};

export default ImpersonateLogin;
